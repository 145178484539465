// LogbookPouchRepo.ts
import PouchDB from "@/repos/PouchDB";
import type { LogbookType } from "@/types";
import type { BaseRepoInterface } from "@/interfaces";
import { v4 as uuidv4 } from "uuid";
import { LOGBOOK_ID_PREFIX } from "@/constants";
import QsoService from "@/services/QsoService";

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
// NOTE: putting this method here lets our callers know we for sure have a
// delete and then it's happy.
interface LogbookRepoInterface extends BaseRepoInterface<LogbookType> {
  delete(id: string): Promise<LogbookType>;
  update(logbook: LogbookType): Promise<LogbookType>;
  search(query: string, limit?: number): Promise<LogbookType[]>;
}
function rankSearchResults(query: string, results: LogbookType[]): LogbookType[] {
  const rankedResults = results.map((doc) => {
    let score = 0;

    // Exact match
    if (doc.title.toLowerCase() === query.toLowerCase()) {
      score = 3;
    }
    // Prefix match
    else if (doc.title.toLowerCase().startsWith(query.toLowerCase())) {
      score = 2;
    }
    // Partial match
    else if (doc.title.toLowerCase().includes(query.toLowerCase())) {
      score = 1;
    }

    return { doc, score };
  });

  // Sort by score in descending order (highest score first)
  rankedResults.sort((a, b) => b.score - a.score);

  // Extract and return sorted documents
  return rankedResults.map((item) => item.doc);
}

const LogbookPouchRepo: LogbookRepoInterface = {
  create: async (params: { attrs: Partial<LogbookType> }): Promise<LogbookType> => {
    const logId = `${LOGBOOK_ID_PREFIX}:${uuidv4()}`;
    const createdAt = new Date().toISOString();
    const updatedAt = new Date().toISOString();
    const title = params.attrs.title?.trim();
    const qsoCount = 0
    const newLogbook = {
      _id: logId,
      createdAt,
      updatedAt,
      qsoCount,
      ...params.attrs,
      title
    };

    await PouchDB.upsert(logId, () => newLogbook);
    return PouchDB.get(logId);
  },

  update: async (logbook: LogbookType): Promise<LogbookType> => {
    const updatedAt = new Date().toISOString();
    logbook.updatedAt = updatedAt;
    logbook.title = logbook.title?.trim();

    // Update qsoCount before upserting
    const qsoCount = await QsoService.countAllByLogbook(logbook._id);
    logbook.qsoCount = qsoCount;

    // Use upsert to apply the changes
    await PouchDB.upsert(logbook._id, () => logbook);

    // Return the updated logbook from the database
    return PouchDB.get(logbook._id);
  },

  findAll: async (): Promise<LogbookType[]> => {
    const result = await PouchDB.allDocs({
      include_docs: true,
      startkey: `${LOGBOOK_ID_PREFIX}:`,
      endkey: `${LOGBOOK_ID_PREFIX}:\ufff0`,
    });

    return result.rows
      .map((row) => row.doc as unknown as LogbookType)
      .sort((a, b) => b.createdAt!.localeCompare(a.createdAt!));
  },

  findOne: async (id: string): Promise<LogbookType> => {
    return PouchDB.get(id);
  },
  search: async (query: string, limit?: number): Promise<LogbookType[]> => {
    const selector = {
      _id: { $gte: `${LOGBOOK_ID_PREFIX}:`, $lte: `${LOGBOOK_ID_PREFIX}:\ufff0` },
      title: { $regex: new RegExp(escapeRegExp(query), 'i') }
    };
    const findOptions: any = { selector };

    // Add limit to findOptions if it's defined
    // if (limit) {
    //   findOptions.limit = limit;
    // }

    const result = await PouchDB.find(findOptions);
    const results = result.docs as unknown as LogbookType[];
    const sortedResults = rankSearchResults(query, results);

    // Apply limit if specified
    return limit ? sortedResults.slice(0, limit) : sortedResults;
  },
  delete: async (id: string): Promise<LogbookType> => {
    const doc = await PouchDB.get(id);
    await PouchDB.remove(doc);

    return Promise.resolve(doc as unknown as LogbookType)
  },
};

export default LogbookPouchRepo;
