import { LOGBOOK_TEMPLATE_NAMES } from "@/constants";
import { normalizeQso } from "@/utils/normalizeQso";
import type { QsoType } from "@/types";

type DupeCheckFunction = (qso1: QsoType, qso2: QsoType) => boolean;
/**
 * Creates a unique key for a QSO based on the template's comparison rules
 */
function createDupeKey(qso: QsoType, template: keyof typeof LOGBOOK_TEMPLATE_NAMES): string {
  const normalizedQso = normalizeQso(qso);
  const baseKey = `${normalizedQso.call}|${normalizedQso.mode}|${normalizedQso.band}|${normalizedQso.qsoDate}`;

  switch (template) {
    case 'POTA':
      return `${baseKey}|${normalizedQso.potaRef || ''}`;
    case 'SOTA':
      return `${baseKey}|${normalizedQso.sotaRef || ''}`;
    default:
      return baseKey;
  }
}

/**
 * Finds duplicate QSOs in an array based on the specified template's rules
 * Time complexity: O(n) where n is the number of QSOs
 * Space complexity: O(n) for the hash map
 * 
 * @param qsos Array of QSOs to check for duplicates
 * @param template The logbook template type to use for comparison rules
 * @returns Array of QSO IDs that are duplicates
 */
export function findDuplicateQsos(
  qsos: QsoType[],
  template: keyof typeof LOGBOOK_TEMPLATE_NAMES
): string[] {
  const duplicateIds: string[] = [];
  const qsoMap = new Map<string, string[]>();

  // Need at least 2 QSOs to have duplicates
  if (qsos.length < 2) {
    return duplicateIds;
  }

  // Process each QSO once, O(n)
  for (const qso of qsos) {
    if (!qso._id) continue;

    const key = createDupeKey(qso, template);
    const existingIds = qsoMap.get(key) || [];

    // If we already have QSOs with this key, they're all duplicates
    if (existingIds.length > 0) {
      // Add current QSO ID if not already in duplicates
      if (!duplicateIds.includes(qso._id)) {
        duplicateIds.push(qso._id);
      }
      // Add all existing QSO IDs if not already in duplicates
      for (const existingId of existingIds) {
        if (!duplicateIds.includes(existingId)) {
          duplicateIds.push(existingId);
        }
      }
    }

    // Add current QSO ID to the map
    qsoMap.set(key, [...existingIds, qso._id]);
  }

  return duplicateIds;
}
export const baseComparison = (qso1: QsoType, qso2: QsoType): boolean =>
  qso1.call === qso2.call &&
  qso1.mode === qso2.mode &&
  qso1.band === qso2.band

const dupeCheckStrategies: Record<
  keyof typeof LOGBOOK_TEMPLATE_NAMES,
  DupeCheckFunction
> = {
  POTA: (qso1, qso2) =>
    baseComparison(qso1, qso2) &&
    qso1.qsoDate === qso2.qsoDate &&
    qso1.potaRef === qso2.potaRef,
  SOTA: (qso1, qso2) =>
    baseComparison(qso1, qso2) &&
    qso1.qsoDate === qso2.qsoDate &&
    qso1.sotaRef === qso2.sotaRef,
  ARRL_FIELD_DAY: baseComparison,
  ARRL_WINTER_FIELD_DAY: baseComparison,
  GENERIC: (qso1, qso2) =>
    baseComparison(qso1, qso2) &&
    qso1.qsoDate === qso2.qsoDate
};

function dupeChecker(
  rawQso1: QsoType,
  rawQso2: QsoType,
  template: keyof typeof LOGBOOK_TEMPLATE_NAMES
) {
  // Normalize QSOs before comparison
  const qso1 = normalizeQso(rawQso1);
  const qso2 = normalizeQso(rawQso2);

  const checkFunction =
    dupeCheckStrategies[template] || dupeCheckStrategies.GENERIC;
  return checkFunction(qso1, qso2);
}


export default dupeChecker
